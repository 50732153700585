/**
 * Created by Giannis Kallergis on 2019-03-24
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

/** Components */
import Avatar from "./Avatar";
import SocialIcon from "./SocialIcon";

/** Utilities */
import config from "../../config/SiteConfig";
import * as Theme from "../../config/Theme";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  padding-left: 1rem;
  a {
    font-size: 1.5rem;
    text-decoration: none;
  }
  p {
    color: ${Theme.colors.secondary};
    margin: 0;
  }
`;

const SocialIcons = styled.div``;

const AuthorHeader = () => (
  <Container>
    <Link to="/">
      <Avatar />
    </Link>
    <Info>
      <Link to="/">{config.siteTitle}</Link>
      <p>{config.siteDescription}</p>
      <SocialIcons>
        <SocialIcon type={"twitter"} stroke={Theme.colors.secondary} size={20} />
        <SocialIcon type={"github"} stroke={Theme.colors.secondary} size={20} />
        <SocialIcon type={"linkedIn"} stroke={Theme.colors.secondary} size={20} />
        <SocialIcon type={"bitBucket"} stroke={Theme.colors.secondary} size={20} />
      </SocialIcons>
    </Info>
  </Container>
);

AuthorHeader.propTypes = {
  dark: PropTypes.bool,
};

AuthorHeader.defaultProps = {};

export default AuthorHeader;
