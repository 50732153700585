/**
 * Created by Giannis Kallergis on 2019-03-24
 */
import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const AvatarContainer = styled.div`
  vertical-align: middle;
  border-radius: 50%;
  width: ${props => (props.small ? "2rem" : "4rem")};
  height: auto;
  overflow: hidden;
  transition: all ${props => props.theme.transitions.normal};
  img {
    border-radius: 50%;
  }
  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
`;

const Avatar = props => (
  <StaticQuery
    query={graphql`
      query Avatar {
        avatarIcon: file(relativePath: { eq: "me.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <AvatarContainer small={props.small}>
        <Img fluid={data.avatarIcon.childImageSharp.fluid} />
      </AvatarContainer>
    )}
  />
);

Avatar.propTypes = {
  small: PropTypes.bool,
};

export default Avatar;
